/* eslint-disable */

class SkipLinks {
  _selector: string;

  constructor(selector: string) {
    this._selector = selector;
    this._init();
  }

  _init() {
    const elements = document.querySelectorAll('[data-js="skiplink"]');

    elements.forEach((element) => {
      element.addEventListener('click', (event) => {
        this.skipTo(event);
      });
    });
  }

  skipTo(event: Event) {
    const target: HTMLElement = event.currentTarget as HTMLElement;
    if (target) {
      const href: string = target.getAttribute('href') ?? '';

      if (href) {
        const dest: HTMLElement = document.querySelector(href) as HTMLElement;

        if (dest) {
          dest.classList.remove('outline-none');
          dest.focus();
        }
      }
    }
  }
}

export default SkipLinks;
