class ShowHideElements {
  _containerEl: HTMLElement;
  _elementsContainerEl: HTMLElement;
  _nextTriggers: NodeList;
  _prevTriggers: NodeList;
  _elements?: NodeList;
  _visibleIndex: number;
  _height: number;

  constructor(
    containerEl: HTMLElement,
    elementsContainerEl: HTMLElement,
    nextTriggers: NodeList,
    prevTriggers: NodeList
  ) {
    this._containerEl = containerEl;
    this._elementsContainerEl = elementsContainerEl;
    this._nextTriggers = nextTriggers;
    this._prevTriggers = prevTriggers;
    this._visibleIndex = 0;
    this._height = 0;

    this._init();
  }

  _init(): void {
    this._elements = this._containerEl.querySelectorAll(
      ':scope [data-show-hide-element]'
    );

    this._nextTriggers.forEach((el) => {
      el.addEventListener('click', () => this.next());
    });

    this._prevTriggers.forEach((el) => {
      el.addEventListener('click', () => this.previous());
    });

    let timeout: number;
    const delay = 50;

    window.addEventListener('resize', () => {
      clearTimeout(timeout);
      timeout = window.setTimeout(() => {
        this.setHeight();
      }, delay);
    });

    window.addEventListener('load', () => {
      this.setHeight();
    });
  }

  setHeight(): void {
    this._height = 0;

    if (this._elements) {
      this._elements.forEach((element) => {
        const el = element as HTMLElement;
        const isHidden = el.classList.contains('hidden');
        if (isHidden) {
          el.classList.remove('hidden');
        }

        if (el.offsetHeight > this._height) {
          this._height = el.offsetHeight;
        }

        if (isHidden) {
          el.classList.add('hidden');
        }
      });
    }

    this._elementsContainerEl.style.height = `${this._height}px`;
  }

  next(): void {
    if (this._elements) {
      const nextIndex =
        this._visibleIndex + 1 === this._elements.length
          ? 0
          : this._visibleIndex + 1;
      const visibleEl = this._elements.item(this._visibleIndex);
      const nextEl = this._elements.item(nextIndex);

      if (visibleEl && nextEl) {
        (visibleEl as HTMLElement).classList.add('hidden');
        (nextEl as HTMLElement).classList.remove(
          'hidden',
          'animate-slide-from-left',
          'animate-slide-from-right'
        );
        (nextEl as HTMLElement).classList.add('animate-slide-from-right');
        this._visibleIndex = nextIndex;
      }
    }
  }

  previous(): void {
    if (this._elements) {
      const prevIndex =
        this._visibleIndex - 1 < 0
          ? this._elements.length - 1
          : this._visibleIndex - 1;
      const visibleEl = this._elements.item(this._visibleIndex);
      const previousEl = this._elements.item(prevIndex);

      (visibleEl as HTMLElement).classList.add('hidden');
      (previousEl as HTMLElement).classList.remove(
        'hidden',
        'animate-slide-from-left',
        'animate-slide-from-right'
      );
      (previousEl as HTMLElement).classList.add('animate-slide-from-left');
      this._visibleIndex = prevIndex;
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('[data-show-hide-list]').forEach((containerEl) => {
    const elementsContainerEl: HTMLElement = containerEl.querySelector(
      '[data-show-hide-elements]'
    ) as HTMLElement;
    const nextTriggers: NodeList = containerEl.querySelectorAll(
      '[data-trigger-next]'
    );
    const prevTriggers: NodeList = containerEl.querySelectorAll(
      '[data-trigger-prev]'
    );
    if (nextTriggers || prevTriggers) {
      new ShowHideElements(
        containerEl as HTMLElement,
        elementsContainerEl,
        nextTriggers,
        prevTriggers
      );
    }
  });
});

export default ShowHideElements;
