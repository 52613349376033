import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';

import Collapse from './components/Collapse';
import ShowHideElements from './components/ShowHideElements';
import SkipLinks from './components/SkipLinks';
import ToggleFilters from './components/ToggleFilters';

type Settings = Partial<{
  SENTRY_DSN: string;
}>;

const settingsElement = document.getElementById('settings');

const settings: Settings =
  settingsElement && settingsElement.textContent
    ? JSON.parse(settingsElement.textContent)
    : {};

Sentry.init({
  dsn: settings.SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

new SkipLinks('[data-js="skiplink"]');

export { Collapse, ShowHideElements, ToggleFilters };
