/* eslint-disable */

class ToggleFilters {
  _containerEl: HTMLElement;
  _elements: NodeList;
  _hiddenElements: HTMLElement[];
  _trigger: HTMLElement;
  _visible: boolean;
  _maxElements: number;

  constructor(
    containerEl: HTMLElement,
    elements: NodeList,
    trigger: HTMLElement
  ) {
    this._containerEl = containerEl;
    this._elements = elements;
    this._trigger = trigger;
    this._visible = false;
    this._maxElements = 6;
    this._hiddenElements = [];

    this._init();
  }

  _init(): void {
    if (this._elements.length > this._maxElements) {
      this._hiddenElements = Array.prototype.slice.call(
        this._elements,
        this._maxElements
      );
      this._hiddenElements.forEach((element) => {
        element.classList.add('hidden');
      });

      this._trigger.classList.remove('hidden');
      this._trigger.addEventListener('click', (event) => {
        this.toggleVisibility(event);
      });
    }
  }

  toggleVisibility(event: Event) {
    const target: HTMLElement = event.currentTarget as HTMLElement;
    const button: HTMLElement = target.querySelector('button') as HTMLElement;
    event.preventDefault();

    if (this._hiddenElements) {
      if (this._visible) {
        this._hiddenElements.forEach((element) => {
          element.classList.add('hidden');
        });
        this._visible = false;
        if (target.dataset.moreText) {
          button.innerText = target.dataset.moreText;
        }
      } else {
        this._hiddenElements.forEach((element) => {
          element.classList.remove('hidden');
        });
        this._visible = true;
        if (target.dataset.lessText) {
          button.innerText = target.dataset.lessText;
        }
      }
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('[data-filter-list]').forEach((containerEl) => {
    const elements: NodeList =
      containerEl.querySelectorAll('[data-filter-item]');
    const trigger: HTMLElement = containerEl.querySelector(
      '[data-filter-toggle]'
    ) as HTMLElement;

    if (elements && trigger) {
      new ToggleFilters(
        containerEl as HTMLElement,
        elements,
        trigger as HTMLElement
      );
    }
  });
});

export default ToggleFilters;
