/* eslint-disable */
type Options = {
  triggerEl: HTMLElement;
  onCollapse?: Function;
  onExpand?: Function;
  onToggle?: Function;
};

const Default = {
  triggerEl: null,
  onCollapse: () => {},
  onExpand: () => {},
  onToggle: () => {},
};

class Collapse {
  _targetEl: HTMLElement;
  _triggerEl: HTMLElement;
  _options: Options;
  _visible: boolean;

  constructor(targetEl: HTMLElement, options: Options) {
    this._targetEl = targetEl;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this._triggerEl = options ? options.triggerEl : Default.triggerEl!;
    this._options = { ...Default, ...options };
    this._visible = false;
    this._init();
  }

  _init() {
    if (this._triggerEl) {
      if (this._triggerEl.hasAttribute('aria-expanded')) {
        this._visible =
          this._triggerEl.getAttribute('aria-expanded') === 'true'
            ? true
            : false;
      } else {
        this._visible = this._targetEl.classList.contains('hidden')
          ? false
          : true;
      }

      this._triggerEl.addEventListener('click', () => {
        this._visible ? this.collapse() : this.expand();
      });
    }
  }

  collapse() {
    this._targetEl.classList.add('hidden');
    if (this._triggerEl) {
      this._triggerEl.setAttribute('aria-expanded', 'false');
    }
    this._visible = false;

    // callback function
    if (this._options.onCollapse) {
      this._options.onCollapse(this);
    }
  }

  expand() {
    this._targetEl.classList.remove('hidden');
    if (this._triggerEl) {
      this._triggerEl.setAttribute('aria-expanded', 'true');
    }
    this._visible = true;

    // callback function
    if (this._options.onExpand) {
      this._options.onExpand(this);
    }
  }

  toggle() {
    if (this._visible) {
      this.collapse();
    } else {
      this.expand();
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('[data-collapse-toggle]').forEach((triggerEl) => {
    const targetEl: HTMLElement = document.getElementById(
      triggerEl.getAttribute('data-collapse-toggle') as string
    ) as HTMLElement;
    if (targetEl) {
      new Collapse(targetEl, {
        triggerEl: triggerEl as HTMLElement,
      });
    }
  });
});

export default Collapse;
